import Flickity from 'flickity';

export default class Slideshows {
  private instances = [] as Array<Flickity>;

  constructor() {
    const observer = new MutationObserver((records) => {
      records.forEach(({ addedNodes }) => {
        addedNodes.forEach(($node) => {
          if ($node instanceof Text) {
            return;
          }

          const walker = document.createTreeWalker($node, NodeFilter.SHOW_ELEMENT);

          do {
            const $currentNode = walker.currentNode as HTMLElement;

            if ($currentNode.hasAttribute('data-slideshow')) {
              this
                .create($currentNode)
                .onResize();
            }
          } while (walker.nextNode() !== null);
        });
      });
    });

    document.querySelectorAll('[data-slideshow]').forEach(($node) => {
      this.create($node as HTMLElement);
    });

    observer.observe(document.body, { childList: true, subtree: true });

    this.onResize();

    window.addEventListener('refresh', this.onRefresh.bind(this));
    window.addEventListener('resize', this.onResize.bind(this));
  }

  create($node: HTMLElement) {
    const slideshow = new Flickity($node, JSON.parse(<string>$node.dataset.slideshow));

    slideshow.on('staticClick', (event, pointer, cellElement, cellIndex) => {
      if (cellIndex !== undefined) {
        slideshow.select(cellIndex);
      }
    });

    slideshow.on('settle', this.onResize.bind(this));

    $node.removeAttribute('data-slideshow');

    this.instances.push(slideshow);

    return this;
  }

  onRefresh() {
    this.instances.forEach((slideshow) => slideshow.resize());
  }

  isPartiallyVisible($el: Element) {
    const bounding = $el.getBoundingClientRect();

    return (
      bounding.left >= 0
      && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  onResize() {
    this.instances.forEach((slideshow) => {
      if (slideshow.cells === undefined) {
        return;
      }

      const $els = slideshow.getCellElements();

      if (Array.isArray($els) === false) {
        return;
      }

      $els.forEach(($el) => {
        $el.classList.toggle('-invisible', this.isPartiallyVisible($el) === false);
      });
    });
  }
}
