import {
  createErrorTracker,
  ErrorTracker,
  Lazy,
} from '@/lib-glue/client-services';

import { log } from '@/lib-glue/logger';
import AppInfo from '@/AppInfo';

import { AppContainer } from '@/container';
import ViewportObserver from '@/dom/ViewportObserver';
import SiteNavigation from '@/components/SiteNavigation';
import AlpineRelay from '@/dom/AlpineRelay';
import Slideshows from '@/components/Slideshows';
import { VideoList } from '@/components/VideoList';

export default class AppMain {
  private container!: AppContainer;

  private videoListAll: any[] = [];

  private errorTracker!: ErrorTracker;
  private viewportObserver!: ViewportObserver;
  private SiteNavigation!: SiteNavigation;
  private alpineRelay!: AlpineRelay;
  private slideshows!: Slideshows;

  public async init() {
    await this.startErrorTracking();
    await this.createServiceContainer();

    this.logAppStartupInfo();

    this.initializeViewportObserver();
    this.initializeSiteNavigation();
    this.initializeVideoList();

    this.alpineRelay = new AlpineRelay();

    this
      .alpineRelay
      .fetch()
      .load();

    this.slideshows = new Slideshows();
  }

  private async createServiceContainer() {
    this.container = {
      appInfo: this.appInfo,
      errorTracker: this.errorTracker,
    };

    return this;
  }

  private async startErrorTracking() {
    this.errorTracker = await createErrorTracker(this.appInfo);
    this.errorTracker.start();

    return this;
  }

  private logAppStartupInfo() {
    log.app.info(
      'Initialized', {
        environment: this.appInfo.appEnvironment,
        version: this.appInfo.appVersion,
        logLevel: this.appInfo.logLevel,
      },
    );
  }

  private initializeViewportObserver() {
    this.viewportObserver = new ViewportObserver();
    this.viewportObserver.init();
  }

  private initializeSiteNavigation() {
    this.SiteNavigation = new SiteNavigation('[data-site-header]');
    this.SiteNavigation.init();
  }

  private initializeVideoList() {
    this.videoListAll = [...document.querySelectorAll('[data-video-gallery-module]')];
    this.videoListAll.forEach((video) => {
      new VideoList(video); // eslint-disable-line no-new
    });
  }

  @Lazy() private get appInfo(): AppInfo {
    return new AppInfo();
  }
}
