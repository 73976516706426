// @ts-nocheck
import Flickity from 'flickity';

const defaults = {
  testClass: '.test',
};

export class VideoList {
  constructor($container, settings = {}) {
    this.$container = $container;
    this.settings = { ...defaults, ...settings };

    this.init();
  }

  async init() {
    if (this.$container === null) {
      return this;
    }

    this.$videoContainer = this.$container.querySelector('[data-container-video]');
    this.$list = this.$container.querySelector('[data-list]');
    // this.$previousButton = this.$container.querySelector('[data-previous]');
    // this.$nextButton = this.$container.querySelector('[data-next]');
    if (this.$list === null
      || this.$previousButton === null
      || this.$nextButton === null
      || this.$videoContainer === null) {
      return this;
    }

    this.$videocells = [...this.$container.querySelectorAll('[data-videocard]')];
    if (this.$videocells.length === 0) {
      return this;
    }

    this.initFlickity();
    this.registerEventHandlers();

    this.flkty.select(0);
    this.$videoContainer.src = this.$videocells[0].dataset.videocard;

    return this;
  }

  initFlickity() {
    // eslint-disable-next-line no-unused-vars
    this.flkty = new Flickity(this.$list, {
      // wrapAround: false,
      prevNextButtons: true,
      pageDots: false,
      cellAlign: 'left',
      groupCells: true,
    });
    this.flkty.on('ready.flickity', () => {
      this.flkty.flickity('resize');
    });
  }

  registerEventHandlers() {
    // this.$previousButton.addEventListener('click', () => {
    //     this.previousSlide();
    // });
    // this.$nextButton.addEventListener('click', () => {
    //     this.nextSlide();
    // });
    this.$videocells.forEach(($cell, index) => {
      $cell.addEventListener('click', () => {
        this.$videoContainer.src = $cell.dataset.videocard;

        // if (window.innerWidth < 1200) {
        //   this.flkty.select(index);
        // }
      });
    });
  }

  previousSlide() {
    this.flkty.previous();
  }

  nextSlide() {
    this.flkty.next();
  }
}

export default VideoList;
