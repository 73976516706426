// @ts-nocheck
export default class AlpineRelay {
  private $nodes: Array<Element> = [];

  fetch() {
    this.$nodes = [...document.querySelectorAll('[x-data]')];

    return this;
  }

  async load() {
    const imports = [];
    const modules = [];

    this.$nodes.forEach(($node) => {
      const deps = [];

      deps.push(
        (($node
          .getAttribute('x-data') || '')
          .match(/^(\w+)/) || [null])[0],
      );

      deps.push(
        ...($node
          .getAttribute('x-deps') || '')
          .split(',')
          .map((dependencyName) => dependencyName.trim()),
      );

      deps
        .filter(Boolean)
        .forEach((moduleName) => {
          imports.push(import(`@/components/${moduleName}`));
          modules.push(moduleName);
        });
    });

    (await Promise.all(imports)).forEach((module, index) => {
      const { default: fn } = module;

      window[modules[index]] = (...args) => {
        try {
          return fn(...args);
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.log(ex);
        }

        return {};
      };
    });

    return import('alpinejs');
  }
}
