const defaults = {};
export default class SiteNavigation {
  $container: String | HTMLElement;
  settings: Object;

  isTouchDevice: Boolean | undefined;
  isLargeMQ: MediaQueryList | undefined;
  isLargeVP: Boolean | undefined;

  $root!: HTMLElement;
  $header!: HTMLElement;
  $toggle!: HTMLElement;
  $closeButtons!: Array<HTMLElement>;
  $back!: HTMLElement;
  $logo!: HTMLElement;
  $brand!: HTMLElement;
  $navigation!: HTMLElement;
  $langToggle!: HTMLElement;

  $burger!: HTMLElement;

  $navLists!: Array<HTMLElement>;
  $navItems!: Array<HTMLElement>;
  $firstLevelList!: HTMLElement;
  $firstLevelItems!: Array<HTMLElement>;
  $secondLevelLists!: Array<HTMLElement>;

  scrollPosition!: number;

  constructor($container: String, settings: Object = {}) {
    this.$container = $container;
    this.settings = { ...defaults, ...settings };
    this.$root = document.documentElement;
  }

  init() {
    if (typeof this.$container === 'string') {
      this.$container = document.querySelector(this.$container) as HTMLElement;
    } else {
      return this;
    }

    this.isLargeMQ = window.matchMedia('(min-width: 1200px)');
    this.isLargeVP = this.isLargeMQ.matches;

    this.$header = document.querySelector('[data-site-header]') as HTMLElement;
    this.$toggle = document.querySelector('[data-toggle-button]') as HTMLElement;
    this.$closeButtons = [...document.querySelectorAll('[data-close-button]')] as Array<HTMLElement>;
    this.$back = document.querySelector('[data-back-button]') as HTMLElement;
    this.$brand = document.querySelector('[data-brand-container]') as HTMLElement;
    this.$logo = this.$brand.querySelector('.brand-logo') as HTMLElement;
    this.$navigation = this.$header.querySelector('[data-navigation]') as HTMLElement;
    this.$langToggle = this.$navigation.querySelector('[data-lang-toggle]') as HTMLElement;

    this.$firstLevelList = this.$navigation.querySelector(':scope > [data-nav-list]') as HTMLElement;

    this.$navLists = [...this.$navigation.querySelectorAll('[data-nav-list]')] as Array<HTMLElement>;
    this.$navItems = [...this.$navigation.querySelectorAll('[data-nav-item]')] as Array<HTMLElement>;
    this.$firstLevelItems = [...this.$firstLevelList.querySelectorAll(':scope > .list > .item')] as Array<HTMLElement>;
    this.$secondLevelLists = [...this.$navigation.querySelectorAll('.nav-list-wrapper,.-second')] as Array<HTMLElement>;

    this.$burger = this.$toggle.querySelector('.hamburger') as HTMLElement;

    this.registerEventListeners();
    this.checkIfTouchDevice();
    this.onScroll();
    return this;
  }

  registerEventListeners() {
    window.addEventListener('scroll', () => this.onScroll());

    this.$back.addEventListener('click', () => this.navigateBack());

    this.$navItems.forEach(($item) => {
      $item.addEventListener('click', () => this.navigateDeeper($item));
    });

    this.$firstLevelItems.forEach(($item: HTMLElement) => {
      $item.addEventListener('click', () => {
        // eslint-disable-next-line no-shadow
        this.$firstLevelItems.forEach(($item: HTMLElement) => {
          $item.classList.remove('-selectedCategory');
        });
        $item.classList.add('-selectedCategory');
        this.showDesktopNav($item);
      });
    });

    this.$toggle.addEventListener('click', () => this.toggleMobileNav());

    this.$closeButtons.forEach(($close: HTMLElement) => {
      $close.addEventListener('click', (event) => {
        event.stopPropagation();
        this.$root.removeAttribute('style');
        this.onScroll();
      });
    });

    if (this.isLargeVP) {
      this.$langToggle.addEventListener('click', (e) => {
        if (this.isTouchDevice && !this.$langToggle.classList.contains('-toggled')) {
          e.preventDefault();
          this.$langToggle.classList.add('-toggled');
        } else {
          this.$langToggle.classList.remove('-toggled');
        }
      });
    }
  }

  // Check if the device supports touch input
  checkIfTouchDevice() {
    if ('ontouchstart' in window || navigator.maxTouchPoints) {
      this.isTouchDevice = true;
    } else {
      this.isTouchDevice = false;
    }
  }

  toggleMobileNav() {
    if (this.isOpen()) {
      this.$header.classList.remove('-open');
      this.$burger.classList.remove('is-active');

      this.$logo.classList.toggle('-negative', !this.isAboveThreshold());
      this.$header.classList.toggle('-inverted', this.isAboveThreshold());

      this.resetSelection();
      this.$root.removeAttribute('style');
    } else {
      Object.assign(this.$root.style, {
        overflow: 'hidden',
        height: '100%',
      });

      this.$header.classList.add('-open');
      this.$burger.classList.add('is-active');

      this.$logo.classList.remove('-negative');
      this.$header.classList.add('-inverted');
    }
  }

  resetSelection() {
    this.$navLists.forEach(($list) => {
      $list.classList.remove('-selectedparentlist');
      $list.classList.remove('-selected');
    });
    this.$firstLevelList.classList.add('-selected');
    this.$brand.classList.remove('-back');
  }

  showDesktopNav($item: HTMLElement) {
    this.$secondLevelLists.forEach(($list: HTMLElement) => $list.classList.remove('-visible'));
    const $selectedList = $item.querySelector('[data-nav-list]') as HTMLElement;
    const $close = $item.querySelector('[data-close-button]') as HTMLElement;

    $selectedList.classList.toggle('-visible');
    this.$header.classList.add('-open');
    $close.classList.add('-visible');

    this.$header.addEventListener('click', (e: Event) => {
      const $target = e.target as HTMLElement;
      if ($target.tagName === 'HEADER') {
        this.$root.removeAttribute('style');
        this.onScroll();
      }
    });

    Object.assign(this.$root.style, {
      overflow: 'hidden',
      height: '100%',
    });

    this.$logo.classList.remove('-negative');
    this.$firstLevelList.classList.add('-inverted');

    this.$header.classList.toggle('-inverted', true);
  }

  navigateDeeper($item: HTMLElement) {
    const $parentList = $item.closest('[data-nav-list]') as HTMLElement;
    const $childList = $item.querySelector('[data-nav-list]') as HTMLElement;

    if ($parentList.classList.contains('-selected') && $childList) {
      $parentList.classList.remove('-selected');
      $parentList.classList.add('-selectedparentlist');
      $childList.classList.add('-selected');
      this.$brand.classList.add('-back');
    }
  }

  navigateBack() {
    const $selected = this.$navigation.querySelector('.-selected') as HTMLElement;

    let $selectedparentlist = $selected.closest('.-selectedparentlist');

    if ($selectedparentlist) {
      $selected.classList.remove('-selected');
      $selectedparentlist.classList.remove('-selectedparentlist');
      $selectedparentlist.classList.add('-selected');

      $selectedparentlist = $selected.closest('.-selectedparentlist');
      if ($selectedparentlist === null) {
        this.$brand.classList.remove('-back');
      }
    }
  }

  isOpen() {
    return this.$header.classList.contains('-open');
  }

  getYPosition() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  isAboveThreshold() {
    return this.getYPosition() > this.$header.getBoundingClientRect().height;
  }

  isScrollingDown() {
    const currentScrollPositon = this.getYPosition();
    return currentScrollPositon > this.scrollPosition;
  }

  onScroll() {
    const currentScrollPositon = this.getYPosition();
    this.resetSelection();

    if (this.isScrollingDown()) {
      this.$header.classList.remove('-visible');
    } else {
      this.$header.classList.add('-visible');
    }

    if (!this.isAboveThreshold()) {
      this.$header.classList.add('-visible');
    }
    this.$header.classList.toggle('-inverted', this.isAboveThreshold());
    this.$logo.classList.toggle('-negative', !this.isAboveThreshold());
    this.$firstLevelList.classList.toggle('-inverted', this.isAboveThreshold());
    this.scrollPosition = currentScrollPositon;

    this.$header.classList.remove('-open');
    this.$secondLevelLists.forEach(($list) => $list.classList.remove('-visible'));
    this.$burger.classList.remove('is-active');
    this.$closeButtons.forEach(($close: HTMLElement) => {
      $close.classList.remove('-visible');
    });
  }
}
