interface ViewportObserverSettings {
  inViewPortClass?: string;
  moduleSelector?: string;
}

const defaults: ViewportObserverSettings = {
  inViewPortClass: '-in-viewport',
  moduleSelector: '[data-in-viewport]',
};

export default class ViewportObserver {
  private settings: ViewportObserverSettings;

  constructor(settings: ViewportObserverSettings = {}) {
    this.settings = {
      ...defaults,
      ...settings,
    };
  }

  public init() {
    const $modules = [...document.querySelectorAll(this.settings.moduleSelector!)];

    if ($modules.length === 0) {
      return this;
    }

    const options: IntersectionObserverInit = {
      root: null,
      threshold: 0,
      rootMargin: '0px 0px 10px 0px',
    };

    const pageObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          }

          entry.target.classList.toggle(this.settings.inViewPortClass!);
          observer.unobserve(entry.target);
        });
      },
      options,
    );

    $modules.forEach((module) => {
      pageObserver.observe(module);
    });

    return this;
  }
}
