// Note: we import the client-services interfaces directly from the source, it is the only way
// webpack does not emit warnings pretending not to recognize the module import
import { Logger } from '@gridonic/client-services/src/core/logging/Logger';
import { ErrorTracker } from '@gridonic/client-services/src/tracking/error/ErrorTracker';
import { Lazy, promise } from '@gridonic/client-services';
import { logger } from '@/lib-glue/logger';
import AppInfo from '@/AppInfo';

// Export interfaces
export {
  Logger,
  ErrorTracker,
  Lazy,
  promise,
};

export async function createErrorTracker(appInfo: AppInfo): Promise<ErrorTracker> {
  const { SentryErrorTracker } = (await import(/* webpackChunkName: "error-tracker" */ '@/lib-glue/client-services/sentry-error-tracker'));

  return new SentryErrorTracker(
    logger, {
      id: appInfo.sentryDsn,
      environment: appInfo.appEnvironment,
      projectName: appInfo.projectName,
      version: appInfo.appVersion,
    },
  );
}
